'use client';

import { isTypedFragment } from '@liquorice/gql-utils';
import { mergePropsClassName } from '@liquorice/utils';
import { LottieOptions, useLottie } from 'lottie-react';
import LogoDark from '../../../public/animations/GAC-Logo-FA-2a.json';
import LogoLight from '../../../public/animations/GAC-Logo-FA-2b.json';
import NextAnchor, { NextAnchorProps } from '../ui/NextAnchor';
import * as styles from './NavBar.css';
import { useNavBar } from './useNavBar';
import { useNavbarActions } from './useNavbarState';

type NavLogo = Omit<NextAnchorProps, 'href'>;

export default function NavLogo(props: NavLogo) {
  const withColorSet = useNavbarActions((s) => s.posColorSet);
  const { page } = useNavBar();
  const isEvent = isTypedFragment(page, 'event');

  const options: LottieOptions = {
    animationData: withColorSet ? LogoLight : LogoDark,
    loop: false,
  };

  const { View } = useLottie(options);

  return (
    <NextAnchor
      href="/"
      aria-label="logo"
      {...mergePropsClassName(props, styles.logo({ withColorSet: withColorSet && !isEvent }))}>
      {View}
    </NextAnchor>
  );
}
