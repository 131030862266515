import { toBoolean, toStringOrNull } from '@liquorice/utils';

export type DateRange = {
  end: string;
  isFuture: boolean;
  isNotPast: boolean;
  isOnGoing: boolean;
  isPast: boolean;
  start: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseDateRange = (data: Maybe<any>): DateRange => {
  const start = toStringOrNull(data?.start) ?? '';
  const end = toStringOrNull(data?.end) ?? '';
  const isFuture = toBoolean(data?.isFuture);
  const isNotPast = toBoolean(data?.isNotPast);
  const isOnGoing = toBoolean(data?.isOnGoing);
  const isPast = toBoolean(data?.isPast);

  return {
    start,
    end,
    isFuture,
    isNotPast,
    isOnGoing,
    isPast,
  };
};
